import Vue from 'vue'
import VueMeta from 'vue-meta'
import vSelect from 'vue-select'
import Swatches from 'vue-swatches'
import Tooltip from 'vue-directive-tooltip'
import Notifications from 'vue-notification'
import infiniteScroll from 'vue-infinite-scroll'
import VueInputAutowidth from 'vue-input-autowidth'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueDraggableResizable from 'vue-draggable-resizable'

//supporting files
import Errors from '../../libraries/errors'

import charmap from '../../widgets/charmap.vue'
import save from '../../widgets/save.vue'
import lastSaved from '../../widgets/LastSaved.vue'

import { ColorPanel, ColorPicker } from 'one-colorpicker'

//components
import LogoLoader from '../../components/logos/LogoLoader.vue'
import Login from '../../components/Login.vue'
import DocumentTypeChooser from '../../components/DocumentTypeChooser.vue'
import MainMenu from '../../widgets/main-menu.vue'
import CheckoutModal from '../../components/modals/CheckoutModal.vue'
import FilenameModal from '../../components/FilenameModal.vue'
import PrintableModal from '../../components/PrintableModal.vue'
import OnboardingModal from '../../components/OnboardingModal.vue'
import ErrorHandlingModal from '../../components/ErrorHandlingModal.vue'
import PrintCovers from '../../components/PrintCovers.vue'
import DocumentsPage from '../../documents/pages.vue'
import Bingo from '../../documents/bingo/bingo.vue'
import Flashcard from '../../documents/flashcard/flashcard.vue'
import OneClickSuccessModal from '../../components/OneClickSuccessModal.vue'
import Paywall from '../../components/Paywall.vue'

import { mapGetters, mapState } from 'vuex'

// app components
import modal from '../../widgets/modal.vue'
import documentComponent from '../../documents/document.vue'

import Lang from '../../libraries/Lang'
import Loader from '../../widgets/Loader.vue'
import fontSelect from '../../widgets/font-select.vue'
import premiumMarker from '../../widgets/premium-marker.vue'
import arrowDownTitle from '../../widgets/arrow-down-title.vue'
import Canny from '../../components/Canny.vue'

// api library
import EventApi from '../../apis/EventApi'

// Repositories
import { Nav } from '../../repos/Nav'
import { Fonts } from '../../repos/Fonts'
import { Modals } from '../../repos/Modals'

//Import the VueX store
import store from '../../store/document'
import SideBar from '../../components/widgets/SideBar.vue'

import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

// Mixins
import WithBrand from '../../mixins/WithBrand'
import WithPageViewTracking from '../../mixins/WithPageViewTracking'
import DocumentHelper from '../../mixins/DocumentHelper'
import DocumentResizeListener from '../../mixins/DocumentResizeListener'
import StatsigHelper from '../../mixins/StatsigHelper'
import TopnavUtil from '../../mixins/TopnavUtil'
import PushListener from '../../mixins/PushListener'
import LogInWatcher from '../../mixins/LogInWatcher'
import WithGrooveHq from '../../mixins/WithGrooveHq'
import WithCustomScrollbar from '../../mixins/WithCustomScrollbar'
import WithImageUpload from '../../mixins/WithImageUpload'

import VueForceNextTick from 'vue-force-next-tick'
import StatsigEvent from '../../common/StatsigEvent'
import UserEvent from '../../objects/UserEvent'

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import IconMagnifyPlus from '../../stories/components/icons/IconMagnifyPlus.vue'
import FaqSection from '../../documents/partials/FaqSection.vue'
import CopyrightDisclaimers from '../../documents/partials/CopyrightDisclaimers.vue'

Vue.use(VueForceNextTick)

Vue.use(Tooltip)
Vue.use(VueMeta)
Vue.use(Notifications)
Vue.use(infiniteScroll)
Vue.use(VueInputAutowidth)

//bootstrap components
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// Color pickers
Vue.use(ColorPanel)
Vue.use(ColorPicker)

Vue.component('SideBar', SideBar)
Vue.component('MainMenu', MainMenu)
Vue.component('Loader', Loader)
Vue.component('MwmModal', modal)
Vue.component('VSelect', vSelect)
Vue.component('Swatches', Swatches)
Vue.component('FontSelect', fontSelect)
Vue.component('PremiumMarker', premiumMarker)
Vue.component('Document', documentComponent)
Vue.component('ArrowDownTitle', arrowDownTitle)
Vue.component('VueDraggableResizable', VueDraggableResizable)
Vue.component('OneClickSuccess', OneClickSuccessModal)
Vue.component('Paywall', Paywall)

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event)
            }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
})

//set up some Vue values
Vue.prototype.$lang = new Lang()

//api
Vue.prototype.$eventApi = EventApi

//repos
Vue.prototype.$nav = Nav
Vue.prototype.$fonts = Fonts
Vue.prototype.$modals = Modals

Sentry.init({
    Vue,
    dsn: window.sentryDSN,
    integrations: [
        new BrowserTracing({
            tracingOrigins: [window.sentryTracingOrigin, /^\//],
        }),
        new Sentry.Replay({
            networkCaptureBodies: true,
            networkDetailAllowUrls: [window.location.origin],
        }),
    ],
    logErrors: true,
    tracesSampleRate: window.sentryTraceSampleRate,
    replaysOnErrorSampleRate: 1.0,
})

window.app = new Vue({
    store,
    el: '#app',
    components: {
        'logo-loader': LogoLoader,
        MaintenanceAlert: () => import('../../components/MaintenanceAlert.vue'),
        //subscription/auth
        Login,

        //Modals
        CheckoutModal,
        FilenameModal,
        PrintableModal,
        ErrorHandlingModal,
        OnboardingModal,
        Canny,

        //Documents page
        DocumentsPage,

        //Bingo
        Bingo,

        //Flashcard
        Flashcard,

        // different forms
        charmap,
        save,
        lastSaved,

        // Faq and Copyright
        FaqSection,
        CopyrightDisclaimers,

        // Print interuptions (not published/not logged in)
        PrintCovers,

        DocumentTypeChooser,
        IconMagnifyPlus,
    },
    mixins: [
        WithBrand,
        WithPageViewTracking,
        DocumentHelper,
        StatsigHelper,
        TopnavUtil,
        PushListener,
        LogInWatcher,
        DocumentResizeListener,
        WithGrooveHq,
        WithCustomScrollbar,
        WithImageUpload,
    ],
    data: {
        zoom: 1,
        subscription_init_load: true,
        mobileAlertDismissed: false,
        mainNavIsActive: false,
        errors: new Errors(),
        clear_items_modal: {
            is_open: false,
        },
        mediumWidth: 1304,
        workspaceWidth: 730,
        resizedUp: false,
        currentWidth: window.innerWidth,
        previousWidth: window.innerWidth,
        allowRescale: false,
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            hasPlan: 'user/hasPlan',
            documentTypes: 'document/allDocumentTypes',
            documentRequiresPagination: 'document/documentRequiresPagination',
            zoomLevels: 'document/getZoomLevels',
            worksheet: 'document/worksheet',
            isWorksheet: 'document/isWorksheet',
            isBingo: 'document/isBingo',
            isFlashcard: 'document/isFlashcard',
            hasPlans: 'subscription/hasPlans',
            error: 'document/apiCallFeedback',
            persistAction: 'document/immediatePersistAction',
            answerable: 'document/answerable',
            showAnswerKey: 'abtests/showAnswerKey',
            isHeaderSticky: 'abtests/stickyHeader',
            isImageUploaderOpen: 'document/isImageUploaderOpen',
        }),
        ...mapState(['user', 'document', 'subscription']),
        screenSizeTooSmall() {
            return this.currentWidth < 925
        },
        documents() {
            return this.document.documents.length
        },
        intentToSaveText() {
            if (!this.isLoggedIn) return 'Save'

            if (this.document.is_loading) return 'Saving...'

            if (this.document.save_document) return 'Saved'

            return 'Save'
        },
        loadCompleted() {
            return {
                type: this.worksheet.type,
                fonts: this.$fonts.ready,
            }
        },
        showDefinition() {
            return ['matching', 'fill_in_the_blank'].includes(this.worksheet.type)
        },
        checkoutPage() {
            if (this.isFlashcard) return 'flashcard'

            if (this.isBingo) return 'bingo'

            return 'worksheet'
        },
        saveComponentStyle() {
            return [{ minWidth: '40%' }, { width: '100%' }, { maxWidth: this.screenSizeTooSmall ? '100%' : '50%' }]
        },
        answer_key: {
            get() {
                return this.isBingo || this.isFlashcard ? 0 : this.document.show_answer_key
            },
            set(value) {
                this.$store.dispatch('document/setDocument', {
                    show_answer_key: value,
                })
            },
        },
        zoomContainerClass() {
            return {
                zoomContainerSticky: this.isHeaderSticky,
                'zoom-container-backdrop': this.isImageUploaderOpen,
            }
        },
    },
    watch: {
        documents() {
            this.$nextTick(() => {
                if (document.getElementById('workspace')) {
                    this.$store.dispatch('document/scaleDocument')
                }
            })
        },
        loadCompleted: {
            deep: true,
            handler() {
                this.$nextTick(() => {
                    if (this.isLoggedIn && window.doc.id) {
                        this.$store.dispatch('document/setUpDocumentUpdateInterval')
                    }
                    if (this.loadCompleted.fonts) {
                        this.$store.dispatch('document/paginateItems')
                    }
                })
            },
        },
        documentRequiresPagination: {
            // repaginate anytime the document changes.
            deep: true,
            handler() {
                this.$nextTick(() => {
                    if (this.loadCompleted.fonts) {
                        this.$store.dispatch('document/paginateItems')
                    }
                })
            },
        },
        showAnswerKey(newValue) {
            if (this.isWorksheet) this.answer_key = newValue
        },
    },
    beforeCreate() {
        this.$store.dispatch('user/initialize')
    },
    async created() {
        window.onbeforeunload = (event) => {
            this.handleExitingDocument()
        }

        window.addEventListener('visibilitychange', (event) => {
            if (this.error || document.visibilityState != 'hidden') return undefined

            this.handleDocumentVisibilityChange()
        })

        await this.$store.dispatch('document/setWindowDocument', window.doc)

        //Initialize statsig before creating document
        await this.$store.dispatch('abtests/initialize', {
            completed: async () => {
                await this.startAbTests()

                if (!this.document.entity_type) {
                    this.logNavigation(StatsigEvent.CREATE_DOCUMENT)
                }
            },
        })

        if (window.copying) {
            this.setInitialDocumentValues()

            if (this.isLoggedIn) {
                this.$store.dispatch('document/setDocument', {
                    entity_type: window.doc.entity_type,
                })

                this.$nextTick(() => {
                    this.$store.dispatch(this.persistAction, true)
                })
            }
        }

        // pass the vue instance into the different stores
        this.$store.dispatch('subscription/setValue', {
            vue: this,
        })
        this.$store.dispatch('cards/setValue', {
            vue: this,
        })
        this.$store.dispatch('user/setValue', {
            vue: this,
        })
        this.$store.dispatch('document/setVueInstance', this)
        this.$store.dispatch('document/setModalInstance', this.$modals)

        if (
            this.isLoggedIn && // if we're logged in
            window.doc.id && // and the document has an id
            window.doc.user_id == this.user.user.id
        ) {
            //and the document belongs to the current user
            this.$store.dispatch('document/setSaveDocument', true) // enable document saving
        }

        if (this.isLoggedIn && window.doc.id) {
            this.$store.dispatch('document/fetchDocumentPurchaseStatus')
        }

        //check for mobileAlertDismissed in local storage
        this.mobileAlertDismissed = window.localStorage.getItem('mobileAlertDismissed') ? true : false
    },
    mounted() {
        //add pinterest JS library
        let pinterest = document.createElement('script')
        pinterest.setAttribute('src', '//assets.pinterest.com/js/pinit.js') //generate partial url
        pinterest.setAttribute('async', true)
        document.getElementsByTagName('head')[0].appendChild(pinterest)

        //add twitter JS library
        let twitter = document.createElement('script')
        twitter.setAttribute('src', 'https://platform.twitter.com/widgets.js') //generate partial url
        twitter.setAttribute('charset', 'utf-8')
        document.getElementsByTagName('head')[0].appendChild(twitter)

        let docZoom = localStorage.getItem('DocumentZoom')

        if (docZoom) {
            docZoom = JSON.parse(docZoom)
            if (docZoom.id === this.$store.state.document.id) {
                this.$store.commit('document/SET_DOCUMENT_ZOOM', docZoom.zoom)
                this.$store.dispatch('document/scaleDocument', true)
            } else {
                localStorage.removeItem('DocumentZoom')
            }
        }

        window.onresize = (event) => {
            this.resizeDocument(event)
            this.updateResizeDirection()
        }

        window.document.addEventListener('DOMContentLoaded', (event) => {
            this.resizeDocument(event)
        })

        this.$store.dispatch('document/adjustZoomContainer')
        this.$store.dispatch('document/paginateItems')
    },
    methods: {
        async startAbTests() {
            await this.$store.dispatch('abtests/loadMakePrintableTests')
            await this.$store.dispatch('abtests/loadShowAnswerKeyTest')

            if (this.isWorksheet) {
                await this.$store.dispatch('abtests/loadWorksheetHeaderAndTitleLabelCombinationTests')
            }
            await this.$store.dispatch('abtests/loadStickyHeaderTests')
        },
        updateResizeDirection() {
            this.currentWidth = window.innerWidth

            if (this.currentWidth > this.previousWidth) {
                this.resizedUp = true
            } else if (this.currentWidth < this.previousWidth) {
                this.resizedUp = false
            }

            this.previousWidth = this.currentWidth
        },
        setZoom(size) {
            this.$store.dispatch('document/setZoom', size)
            this.$store.dispatch('document/scaleDocument', true)
            this.$store.dispatch('document/checkScroll')
        },
        isDocumentHidden(selectorId, containerId) {
            const selectedElement = document.getElementById(selectorId)
            const selectedElementBoundingRect = selectedElement.getBoundingClientRect()
            const viewportWidth = window.innerWidth || document.documentElement.clientWidth

            let selectedElementRightBoundary = selectedElementBoundingRect.right

            if (containerId) {
                let containerElement = document.getElementById(containerId)
                let containerStyles = window.getComputedStyle(containerElement)
                let rightPadding = parseFloat(containerStyles.getPropertyValue('padding-right'))

                selectedElementRightBoundary += rightPadding
            }

            return selectedElementRightBoundary > viewportWidth
        },
        intentToSave() {
            if (this.isLoggedIn) {
                this.$modals.open('filename')
            } else {
                this.$modals.open('logIn')
                this.$modals.logIn.active_tab = 'sign_up'
                this.$eventApi.create_event(UserEvent.SIGN_UP_SAVE_DOCUMENT)
            }
        },
        setDocumentType(key) {
            this.$store.dispatch('document/setDocumentType', key).then(() => {
                this.$nav.setPanel('header', 'edit')
                EventApi.create_event('button interface - ' + key)
            })
        },
        //mobile devices
        dismissMobileAlert() {
            this.mobileAlertDismissed = true
            window.localStorage.setItem('mobileAlertDismissed', true)
        },
        getDocumentType(key) {
            for (let i = 0; i < this.documentTypes; i++) {
                if (this.documentTypes[i].key == key) {
                    return this.documentTypes[i]
                }
            }
        },
        goto(e) {
            this.$nextTick(() => {
                setTimeout(() => {
                    const elem = document.getElementById(e.ref)

                    if (!elem || (elem && this.isInViewport(elem))) return
                    let anchorSelector = this.getAnchor(e.ref)

                    const anchor = document.getElementById(anchorSelector)

                    if (anchor) {
                        anchor.scrollIntoView({
                            behavior: 'smooth',
                        })
                    }

                    const elemBoundings = elem?.getBoundingClientRect()
                    if (elemBoundings && elemBoundings?.top < 50) {
                        elem.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                        })
                    } else if (elemBoundings) {
                        if (
                            (e.ref.includes('preview') && window.innerHeight - elemBoundings.top < 150) ||
                            (e.ref.includes('goto-id') && window.innerHeight - elemBoundings.top < 500)
                        ) {
                            elem.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center',
                            })
                        }
                    }
                }, 25)
            })
        },
        isInViewport(element) {
            const rect = element.getBoundingClientRect()
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            )
        },
        getAnchor(ref) {
            if (ref === 'docHeader') {
                return 'anchor-docHeader'
            }

            if (ref === 'instructions' || ref === 'bingo_words') {
                return `anchor-${ref}-0`
            }

            if (ref === 'bingo_call_list') {
                return `anchor-${ref}`
            }

            return `anchor-${ref.substr(8)}`
        },
        handleDocumentVisibilityChange() {
            if (this.document.hasModification) {
                // If user is logged in, save the document.
                if (this.isLoggedIn) {
                    this.$store.dispatch('document/updateDocument')

                    this.$eventApi.send_beacon(UserEvent.LEAVING, this.document.id, this.document.hasModification)
                    return
                }

                // If user is not logged in, display the confirmation.
                this.$eventApi.send_beacon(UserEvent.LEAVING, 'unsaved changes prompt')
                return 'Your changes are not saved! Are you sure you want to go?'
            } else {
                this.$eventApi.send_beacon(UserEvent.LEAVING, this.document.id)
            }

            return
        },
        handleExitingDocument() {
            if (this.isLoggedIn) {
                if (this.document.hasModification) {
                    this.$store.dispatch('document/updateDocument')
                }

                this.$eventApi.send_beacon(UserEvent.LEAVING, this.document.id, true)
                return
            }

            // If user is not logged in, display the confirmation.
            if (this.document.hasModification) {
                this.$eventApi.send_beacon(UserEvent.LEAVING, 'unsaved changes prompt')
                return 'Your changes are not saved! Are you sure you want to go?'
            }
        },
    },
})
